<template>
  <div class="businessGood">
    <div class="tab">
      <div class="tabBody">
        <div
          @click="selectOrder(1)"
          :class="['item', { active: requestData.status == 1 }]"
        >
          待核销
        </div>
        <div
          @click="selectOrder(2)"
          :class="['item', { active: requestData.status == 2 }]"
        >
          已核销
        </div>
      </div>
    </div>
    <div class="listbody">
      <v-list
        :finished="finished"
        :finishedText="finishedText"
        @onLoad="onLoad"
        ref="list"
        :immediateCheck="immediateCheck"
      >
        <div
          class="order"
          v-for="(item, index) in listData"
          :key="index"
          @click="toDetail(item)"
        >
          <div class="order-t">
            <div class="order-tl">兑换时间：{{ item.insertTime }}</div>
            <div class="order-tr">{{ item.status }}</div>
          </div>
          <div class="order-b">
            <div class="order-bl">
              <img :src="item.imageUrl" alt="" />
            </div>
            <div class="order-br">
              <div class="title">{{ item.title }}</div>
              <div class="price">{{ item.pointsPrice }}积分</div>
              <div class="num">x{{ item.pointsPrice }}</div>
            </div>
          </div>
        </div>
      </v-list>
    </div>
  </div>
</template>

<script>
import { getOrderListUrl } from "./api.js";
import { getHashParam } from "@/utils/utils.js";
var moment = require("moment");
export default {
  name: "businessGood",
  data() {
    return {
      finishedText: "没有更多了",
      finished: false,
      requestData: {
        status: 1,
        curPage: 1,
        pageSize: 10,
      },
      shopId: "",
      listData: [],
      immediateCheck: true,
    };
  },
  components: {},
  destroyed() {},
  computed: {
    communityId() {
      return this.$store.state.communityId;
    },
    userId() {
      return this.$store.state.userId;
    },
    roomId() {
      return this.$store.state.houseId;
    },
    tenantId() {
      return this.$store.state.tenantId;
    },
  },
  created() {
    this.shopId = this.$route.query.shopId;
  },
  mounted() {},
  methods: {
    selectOrder(status) {
      this.requestData.curPage = 1;
      this.listData = [];
      this.requestData.status = status;
      this.immediateCheck = true;
      this.getActivityList();
    },
    toDetail(item) {
      this.$router.push({
        name: "businessGoodDetail",
        query: { id: item.id },
      });
    },
    onLoad() {
      this.getActivityList();
    },
    getActivityList() {
      let params = {
        curPage: this.requestData.curPage++,
        pageSize: this.requestData.pageSize,
        type: this.requestData.status,
        shopId: this.shopId,
      };
      this.$axios.get(`${getOrderListUrl}`, { params: params }).then((res) => {
        console.log(res, 333);
        if (res.code === 200) {
          if (res.data.pages <= res.data.current) {
            this.finished = true;
          } else {
            this.$refs.list._data.loading = false;
          }
          this.finishedText = "没有更多了";
          if (res.data.records.length === 0) {
            this.finishedText = "";
            return;
          }
          res.data.records.forEach((item) => {
            if (item.skuImageUrl) {
              item.skuImageUrl = this.$handleImg(300, 300, item.skuImageUrl);
            }
            this.listData.push(item);
          });
        } else {
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.businessGood {
  min-height: 100%;
  background: #f9f9f9;
  .tab {
    // padding: 0 40px;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    box-sizing: border-box;
    background: #ffffff;
    .tabBody {
      padding: 70px 0 36px;
      display: flex;
      font-size: 32px;
      font-weight: bold;
      color: #347eff;
      line-height: 44px;
      .item {
        flex: 1;
        font-size: 34px;
        line-height: 40px;
        text-align: center;
        color: #a3a3a3;
      }
      .active {
        position: relative;
        color: rgba(254, 150, 22, 1);
        &::after {
          position: absolute;
          left: 50%;
          bottom: -12px;
          transform: translateX(-50%);
          content: "";
          width: 34px;
          height: 6px;
          background: rgba(254, 150, 22, 1);
          border-radius: 3px;
        }
      }
    }
  }
  .listbody {
    padding-top: 146px;
  }
  .order {
    margin: 26px 28px 0 28px;
    background: #ffffff;
    box-shadow: 0px 4px 16px 0px rgba(225, 225, 225, 0.5);
    border-radius: 16px;

    .order-t {
      padding: 0 20px;
      line-height: 78px;
      font-size: 28px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.5);
      border-bottom: 2px solid rgba(0, 0, 0, 0.06);
      display: flex;
      justify-content: space-between;
      .order-tr {
        color: rgba(254, 150, 22, 1);
      }
    }
    .order-b {
      display: flex;
      padding: 22px;

      position: relative;
      .order-bl {
        width: 180px;
        height: 180px;
        margin-right: 30px;
        background: #f6f6f6;
        text-align: center;
        border-radius: 16px;
        padding: 10px;
        img {
          width: 100;
          height: 100%;
          border-radius: 16px;
          vertical-align: middle;
        }
        &:after {
          content: "";
          display: inline-block;
          width: 0;
          height: 100%;
          vertical-align: middle;
        }
      }
      .order-br {
        font-size: 32px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.85);
        line-height: 44px;
        padding-top: 30px;
        .title {
          margin: 0px 0 12px 0;
          font-size: 32px;
          font-weight: 600;
          color: rgba(0, 0, 0, 0.85);
          line-height: 44px;
          width: 260px;
        }
        .price {
          color: rgba(0, 0, 0, 0.85);
          position: absolute;
          top: 58px;
          right: 28px;
        }
        .num {
          color: rgba(0, 0, 0, 0.85);
          position: absolute;
          font-size: 24px;
          top: 108px;
          right: 28px;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.25);
          line-height: 34px;
        }
      }
    }
  }
}
</style>
